/* eslint-disable no-empty */
import {
    SmartHttp
} from "@/utils/smart-core-util";
import "smart-core-util/lib/index.css";
import {
    getCookie
} from "tiny-cookie";
import ImageCompressor from "image-compressor.js";

let urls = {
    urlUpload: `/foundation/aliyun/api/oss/upload`, // 上传
    urlDelete: `/foundation/aliyun/api/oss/delete`, // 删除
    urlMapping: `/foundation/aliyun/api/oss/mapping`, // mapping关联
    urlQuery: `/foundation/aliyun/api/oss/query`, // 查询
    uploadScan: "/api/ocr/invoice/scan", // 发票上传识别
    MappingUpload: "/foundation/aliyun/api/oss/MapingUpload" // mapping,upload接口二合一
};

// host 网关地址，默认从cookie中的Uris获取
class UploadPlug {
    constructor({
        host,
        timeout,
        newUrls = null
    }) {
        this.divEle =
            document.getElementById("uploadDiv") || document.createElement("div");
        if (!this.divEle.hasAttribute("id")) {
            this.divEle.setAttribute("id", "uploadDiv");
            document.body.appendChild(this.divEle);
        }
        if (newUrls) {
            urls = Object.assign(urls, newUrls)
        }

        this.api = new SmartHttp({
            baseURL: host,
            disableLoadingAnimation: true,
            timeout: timeout || 20000
        }).request;
    }

    // 压缩文件
    async compress(fileObject) {
        return new Promise(function (resolve) {
            new ImageCompressor(fileObject, {
                quality: 0.6, // 压缩图片质量，值越大越高清，但size越大（一般0.6--0.8）
                maxHeight: 4096, // 最大分辨率 高/宽
                maxWidth: 4096,
                success(result) {
                    resolve(result);
                },
                error(e) {
                    console.log(e.message);
                    resolve(fileObject);
                }
            });
        });
    }

    // 判断是否为ie浏览器
    judgeIE() {
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
            return true;
        } else {
            return false;
        }
    }

    removePicExif(file) {
        return new Promise(reslove => {
            if (file[0]) {
                let reader = new FileReader();
                let image = new Image();
                reader.onload = function (ev) {
                    image.src = ev.target.result;
                    image.onload = function () {
                        const ctxWidth = this.width;
                        const ctxHeight = this.height;
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");
                        canvas.width = ctxWidth;
                        canvas.height = ctxHeight;
                        ctx.drawImage(this, 0, 0, ctxWidth, ctxHeight);
                        canvas.toBlob(blob => {
                                const newFile = new File([blob], file[0].name, {
                                    type: "image/jpeg",
                                    lastModified: Date.now()
                                });
                                reslove(newFile);
                            },
                            "image/jpeg",
                            .9
                        );
                    };
                };
                reader.readAsDataURL(file[0]);
            }
        });
    }

    // 拍照
    async cameraFile({
        callback = function (data) {
            console.log(data);
        }
    }) {

        // 如果在app中则检查相机权限
        if (window.flutter_inappwebview) {
            let permission = await window.flutter_inappwebview.callHandler("Permission.checkAndRequestCamera");
            if (!permission) {
                ccApp.$itoast.fail("请开启相机权限");
            }
        }


        // 每次先清空divEle
        this.divEle.innerHTML = "";
        // let _this = this;
        let inputEle = document.createElement("input");
        inputEle.setAttribute("type", "file");
        //判断一下如果是只允许拍照才加这个
        inputEle.setAttribute("accept", "image/*");
        inputEle.setAttribute("capture", "camera");
        let id = new Date().getTime();
        inputEle.setAttribute("id", id);
        inputEle.style.display = "none";
        this.divEle.append(inputEle);

        inputEle.click();
        inputEle.onchange = async (event) => {
            let files =
                event.target.files ||
                inputEle.files ||
                document.getElementById(id).files;
            let fileObject = [];
            this.removePicExif(files).then(res => {
                let tempFileObject = {
                    indexId: res.name,
                    url: window.URL.createObjectURL(res),
                    fileObject: res
                };
                fileObject.push(tempFileObject); // 保存文件对象（是文件对象）

                callback(fileObject);
            });
        };


    }

    // 本地 选择文件(允许选择多张图片)
    async localUpload({
        accepts,
        multiple = false,
        callback = function (data) {
            console.log(data);
        }
    }) {
        let isIe = this.judgeIE();
        // 每次先清空divEle
        this.divEle.innerHTML = "";
        // 文件处理
        let compileFile = async files => {
            let fileObject = [];
            for (let i = 0; i < files.length; i++) {
                let tempFileObject = {
                    indexId: files[i].name, //new Date().getTime()
                    url: window.URL.createObjectURL(files[i]),
                    fileObject: files[i]
                };
                fileObject.push(tempFileObject); // 保存文件对象（是文件对象）
            }
            callback(fileObject);
        };

        if (isIe) {
            window.inputChange = async function (event) {
                let files = event.target.files;
                compileFile(files);
            };
            let inputEle = `<input id="ieTest" type="file" onchange="(function(event){inputChange(event)})(event)" style="display:none;"/>`;
            this.divEle.innerHTML = inputEle;
            document.getElementById("ieTest").click();
        } else {
            let inputEle = document.createElement("input");
            inputEle.setAttribute("type", "file");
            // 设置可以选择多张图片
            if (multiple) {
                inputEle.setAttribute("multiple", "multiple");
            }
            // input accept允许类型。
            if (accepts) {
                inputEle.setAttribute("accept", accepts);
            }
            let id = new Date().getTime();
            inputEle.setAttribute("id", id);
            inputEle.style.display = "none";
            this.divEle.appendChild(inputEle);

            inputEle.click();
            inputEle.onchange = async function (event) {
                let files =
                    event.target.files ||
                    inputEle.files ||
                    document.getElementById(id).files;
                compileFile(files);
            };
        }
    }

    // 查询 已上传的文件
    async queryFile({
        filterData,
        callback = function (data) {
            console.log(data);
        }
    }) {
        // roche查询添加 Platform=1
        if (getCookie("tenant") == "roche") {
            Object.assign(filterData, {
                Platform: 1
            });
        }
        let response = await this.api.post(urls.urlQuery, filterData);
        callback(response);
    }

    // 添加上传文件（剔除不需要上传的文件）
    // mapping,upload接口二合一
    async addAsync({
        fileList,
        ossKey,
        bucketAlias,
        mustGeoLocation,
        geoLocation,
                       mappingData,
                       isMapping,
        progressCallback = function (data) {
            console.log(data);
        },
        callback = function (data) {
            console.log(data);
        }
    }) {
        let _this = this;
        let result = {
            status: false,
            msg: ""
        };
        if (!fileList.length) {
            result.status = false;
            result.msg = "上传对象不能为空！";
            return result;
        }
        if (mustGeoLocation && (!geoLocation.lat || !geoLocation.lng)) {
            result.status = false;
            result.msg = "请打开位置信息获取权限！";
            callback([result]);
            return result
        }

        let uploadResult = fileList.map(async (item, index) => {
            item.fileObject = await _this.compress(item.fileObject);
            let response = await _this.uploadAsync({
                fileItem: item.fileObject,
                ossKey: ossKey,
                bucketAlias: bucketAlias,
                geoLocation: geoLocation,
                index: index,
                mappingData: mappingData,
                isMapping: isMapping,
                progressCallback: progressCallback
            });
            return response;
        });
        Promise.all(uploadResult).then(function (result) {
            //这里等所有上传都成功返回数据才执行
            callback(result);
        });
    }

    // 上传接口
    // mapping,upload接口二合一
    async uploadAsync({
        fileItem,
        ossKey,
        bucketAlias,
        geoLocation,
        index,
                          mappingData,
                          isMapping,
        progressCallback
    }) {
        let lat = geoLocation ? geoLocation.lat : "";
        let lon = geoLocation ? geoLocation.lng : "";
        var formData = new FormData();
        console.log("file", fileItem)
        formData.append("file", fileItem, fileItem.name);
        if (ossKey) {
            formData.append("ossKey", ossKey + "-" + +new Date());
        }
        formData.append("bucketAlias", bucketAlias);
        formData.append("latitude", lat);
        formData.append("longitude", lon);

        // 罗氏增加Platform=1(注：formData参数可以以一个json对象传递进来,遍历添加)
        let tenant = getCookie("tenant") || getCookie("tenant_code");
        if (tenant == "roche") {
            formData.append("Platform", 1);
        }
        formData.append("IsNeedMapping",isMapping)
        if(isMapping) {
            formData.append("mappingData",JSON.stringify(mappingData))
        }

        var response = await this.api.post(urls.MappingUpload, formData, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: e => {
                let result = {
                    index: null,
                    percentage: null
                };
                result.percentage = Math.round((e.loaded * 100) / e.total) || 0;
                result.index = index;
                progressCallback(result);

                // 测试进度条
                // let i = 0;
                // let timer = setInterval(() => {
                //   progressCallback({index: 0, percentage: i += 10});
                //   if(i == 100) {
                //     clearInterval(timer);
                //   }
                // }, 1000);
            }
        });

        let resCallback = {};
        if (response.success) {
            resCallback.status = true;
            resCallback.code = response.code;
            resCallback.content = response.content;
        } else {
            resCallback.status = false;
            resCallback.code = response.code;
            resCallback.content = {
                msg: "上传失败"
            };
        }
        return resCallback;
    }

    // 删除
    async deleteAsync({
        fileItem,
        bucketAlias,
        callback
    }) {
        let callbackData =
            callback ||
            function (data) {
                console.log(data);
            };
        if (typeof callbackData != "function") {
            alert("回调应该是一个方法！");
            return;
        }

        // 接口删除(这里只是删除阿里云服务上的图片，删除关联属于业务需求，调用者自己根据需要删除。)
        let params = {
            bucketAlias: bucketAlias,
            fileKey: fileItem.fileKey
        };

        // roche delete Platform=1
        if (getCookie("tenant") == "roche") {
            Object.assign(params, {
                Platform: 1
            });
        }
        // emp
        if (getCookie('tenant') == 'emp-viatris') {
            params = {
                fileKeys: [fileItem.fileKey]
            }
        }

        let response = await this.api.post(urls.urlDelete, params);
        if (response.success) {
            callbackData(response);
        } else {
            callbackData({
                success: false,
                msg: "删除失败！"
            });
        }
    }

    // mapping 关联
    async mappingAsync({
        mappingData,
        fileKeys,
        callback
    }) {
        if (!fileKeys) throw "no file uploaded";
        if (!mappingData) throw "no mappingData define";

        let callbackData =
            callback ||
            function (data) {
                console.log(data);
            };
        if (typeof callbackData != "function") {
            alert("回调应该是一个方法！");
            return;
        }

        var input = {
            fileKeys: fileKeys, // 数组
            mappingData: mappingData,
            Origin: mappingData.Origin
        };

        // roche mapping Platform=1
        if (getCookie("tenant") == "roche") {
            Object.assign(input, {
                Platform: 1
            });
        }

        let response = await this.api.post(urls.urlMapping, input);
        callbackData(response);
        return response;
    }

    // OCR识别
    async ocrAsync({
        url,
        businessData,
        callback
    }) {
        let callbackData =
            callback ||
            function (data) {
                console.log(data);
            };
        if (typeof callbackData != "function") {
            alert("回调应该是一个方法！");
            return;
        }

        var formData = new FormData();
        formData.append("url", url);
        formData.append("businessData", JSON.stringify(businessData));
        var response = await this.api.post(urls.uploadScan, formData, {
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        callbackData(response);
        return response;
    }
}

export default UploadPlug;
